import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from "@/views/LoginView";
import IndexView from "@/views/IndexView.vue";
import BusPassengerView from "@/views/BusPassengerView.vue";
import MergedIndexView from "@/views/MergedIndexView.vue";

const routes = [
    {
        path: '/foiwpfowje', name: 'index', component: IndexView, meta: {
            auth: true
        }
    },
    {
        path: '/', name: 'index', component: MergedIndexView, meta: {
            auth: true
        }
    },{
        path: '/bus/passenger/:busId', name: 'BusPassengerView', component: BusPassengerView, meta: {
            auth: true
        }
    }, {
        path: "/login", name: "login", component: LoginView, meta: {
            auth: false
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(), routes
})

export default router

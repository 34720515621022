const base = {
    baseUrl: "",
    wechatRedirectUrl: "/login/weChatLogin?url={0}",
    getMyRole: "/api/role/getMyRole",
    wechatCodeLogin: "/login/getUserTokenByCode?code={0}",
    loginCaptchaToken: "/login/captchaToken",

    userInfo: "/api/user/app/my/info",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=1&env={0}",
    filters: {
        0: '/api/channel/getChannelDataQueryForm',
    },
    getOssKeySecret: "/api/oss/getOssKeySecret",
    getH5DashboardData: "/api/statistic/h5dashboardData",
    getStatisticData: "/api/channel/queryChannelData",
    getWechatRedirectUrl: "/login/weChatLogin?url={0}",

    applySmsCaptcha: "/api/sale/applySmsBind?phone={0}",
    applySmsBind: "/api/sale/bindPhone",
    captcha4mobileLogin: "/api/open/login/captcha4mobileLogin?phone={0}",
    smsLogin: "/api/open/login/busLeaderLogin",
    getMyBus: "/api/bus/myBuses",
    getBusPassengers: "/api/bus/getBusPassenger?busId={0}",
    proxyCallPassenger: "/api/bus/call?passengerId={0}",
    proxyCallMergedPassenger: "/api/bus/merged/call?passengerId={0}",
    clockInPassenger: "/api/bus/clockIn?passengerId={0}",
    mergedClockInPassenger: "/api/bus/merged/clockIn?passengerId={0}",
    getMergedBusPassengers: "/api/bus/getMergedBusPassengers",

    infiniteLoadings: {

    }

}

export default base;
import axios from "../utils/http";
import path from "./path"
import {Base64} from 'js-base64';

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}

const api = {
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    getEventTypes() {
        return axios.get(path.baseUrl + path.getEventTypes)
    },
    getUserInfo() {
        return axios.get(path.baseUrl + path.userInfo)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout(env) {
        return axios.get(path.baseUrl + stringFormat(path.logout, [env]))
    },
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret);
    },
    getH5DashboardData() {
        return axios.get(path.baseUrl + path.getH5DashboardData);
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken);
    },
    getListFilter(filterType, query) {
        return axios.get(path.baseUrl + path.filters[filterType], {params: query})
    },
    getStatisticData(query) {
        return axios.get(path.baseUrl + path.getStatisticData, {params: query})
    },
    getWechatRedirectUrl(url) {
        return axios.get(path.baseUrl + stringFormat(path.getWechatRedirectUrl, [Base64.encode(url)]))
    },
    wechatCodeLogin(code) {
        return axios.get(path.baseUrl + stringFormat(path.wechatCodeLogin, [code]))
    },
    applySmsCaptcha(phone) {
        return axios.get(path.baseUrl + stringFormat(path.applySmsCaptcha, [phone]))
    },
    applySmsBind(data) {
        return axios.get(path.baseUrl + path.applySmsBind, {params: data})
    },
    getInfiniteLoadingList(i, method, query) {
        if ('GET' == method) {
            return axios.get(path.baseUrl + path.infiniteLoadings[i], {params: query})
        }
        return axios.post(path.baseUrl + path.infiniteLoadings[i], query)
    },
    captcha4mobileLogin(phone) {
        return axios.get(path.baseUrl + stringFormat(path.captcha4mobileLogin, [phone]))
    },
    smsLogin(data) {
        return axios.post(path.baseUrl + path.smsLogin, data)
    },
    getMyBus() {
        return axios.get(path.baseUrl + path.getMyBus)
    },
    getBusPassengers(busId) {
        return axios.get(path.baseUrl + stringFormat(path.getBusPassengers, [busId]))
    },
    proxyCallPassenger(id) {
        return axios.get(path.baseUrl + stringFormat(path.proxyCallPassenger, [id]))
    },
    proxyCallMergedPassenger(id) {
        return axios.get(path.baseUrl + stringFormat(path.proxyCallMergedPassenger, [id]))
    },
    clockInPassenger(id) {
        return axios.get(path.baseUrl + stringFormat(path.clockInPassenger, [id]))
    },
    mergeClockInPassenger(id) {
        return axios.get(path.baseUrl + stringFormat(path.mergedClockInPassenger, [id]))
    },
    getMergedBusPassengers() {
        return axios.get(path.baseUrl + path.getMergedBusPassengers)
    }
}

export default api;
<template>
  <div class="pd16 box colFlex">
    <nut-row class="bothCenter">
      <nut-col :span="6" class="leftCenter">
        <span>&nbsp;</span>
      </nut-col>
      <nut-col :span="12" class="bothCenter">
        <span class="ft24 boldFont">我负责的车次</span>
      </nut-col>
      <nut-col :span="6" class="rightCenter">
        <span class="lightText" @click="logout">退出</span>
      </nut-col>
    </nut-row>
    <template v-for="b in bus" :key="`bus_${b.id}`">
      <div class="bus colFlex pd12 box brd10 mt16" @click="$router.push('/bus/passenger/'+b.id)">
        <span>班次：{{ b.busName }}</span>
        <span>车次：{{ b.busIndex }}</span>
        <span>司机：{{ b.driverName }}</span>
        <span>司机手机号：{{ b.driverPhone }}</span>
        <span>随车负责人：{{ b.leaderName }}</span>
        <span>随车负责人手机号：{{ b.leaderPhone }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "IndexView",
  components: {},
  data() {
    return {
      bus: [],
    }
  },
  methods: {
    logout(){
      localStorage.clear()
      this.init()
    },
    init() {
      api.getMyBus()
          .then(ret => {
            this.bus = ret;
          })
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style scoped lang="scss">
.bus {
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.2);
}
</style>